import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#818AD8',
    },
    secondary: {
      main: '#F8F8FF',
    },
  },
});

export default theme;
