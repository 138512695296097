import InsightsShelf from './InsightsShelf';
type Props = {
  negative?: string;
  neutral?: string;
  positive?: string;
  emptyState?: boolean;
};

function SentimentBar({
  negative = '60%',
  neutral = '15%',
  positive = '25%',
  emptyState = true,
}: Props) {
  return (
    <div className="pb-4">
      <div className="flex h-4 md:h-7 w-full rounded-full">
        <div
          style={{width: `${negative}`}}
          className={`h-full rounded-s-full border-y-2 border-l-2 ${
            emptyState
              ? 'bg-[#242932] border-[#383C44]'
              : 'bg-[#FD6779] bg-opacity-30 border-[#FD6779]'
          }`}
        ></div>
        <div
          style={{width: `${neutral}`}}
          className={`h-full border-2 ${
            emptyState
              ? 'bg-[#242932] border-[#383C44]'
              : 'bg-[#FBBF5D] bg-opacity-30 border-[#FBBF5D]'
          }`}
        ></div>
        <div
          style={{width: `${positive}`}}
          className={`h-full rounded-e-full border-y-2 border-r-2 ${
            emptyState
              ? 'bg-[#242932] border-[#383C44]'
              : 'bg-[#61CDA8] bg-opacity-30 border-[#61CDA8]'
          }`}
        ></div>
      </div>
      {!emptyState && (
        <InsightsShelf
          items={[
            `${negative} Negative`,
            `${neutral} Neutral`,
            `${positive} Positive`,
          ]}
        />
      )}
    </div>
  );
}

export default SentimentBar;
