export default function PercentToInt(percentageString: string): number {
  const trimmedString = percentageString.trim();

  if (trimmedString.endsWith('%')) {
    const numericString = trimmedString.slice(0, -1);
    const numberValue = Number(numericString);
    if (!isNaN(numberValue)) {
      return numberValue;
    }
  }

  return 0;
}
