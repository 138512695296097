import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#03A9F4"
        d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
      />
      <path
        fill="#fff"
        d="M39.548 14.359c-1.17.51-2.406.85-3.673 1.007a6.335 6.335 0 0 0 2.805-3.524 12.736 12.736 0 0 1-4.042 1.544 6.372 6.372 0 0 0-11.028 4.358c-.004.488.045.976.149 1.453a18.051 18.051 0 0 1-13.14-6.666 6.379 6.379 0 0 0 1.96 8.526 6.29 6.29 0 0 1-2.88-.787v.07a6.404 6.404 0 0 0 5.107 6.259 6.357 6.357 0 0 1-1.67.208 5.689 5.689 0 0 1-1.21-.107 6.438 6.438 0 0 0 5.959 4.44 12.818 12.818 0 0 1-7.895 2.719 11.52 11.52 0 0 1-1.525-.089 17.933 17.933 0 0 0 9.79 2.861c11.73 0 18.145-9.716 18.145-18.138 0-.281 0-.553-.023-.825a12.683 12.683 0 0 0 3.171-3.31Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
