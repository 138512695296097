type Props = {
  items: string[];
};

function InsightsShelf({items}: Props) {
  return (
    <div className="flex flex-row h-7 py-6">
      {items.map((item, index) => (
        <div
          key={index}
          className={`h-4 md:h-7 pr-[7px] md:pr-3.5 border-white ${
            index < items.length - 1 ? 'border-r-2 mr-[7px] md:mr-[14px]' : ''
          }`}
        >
          <p className="text-xs md:text-base">{item}</p>
        </div>
      ))}
    </div>
  );
}

export default InsightsShelf;
