import React from 'react';
import ImageUpload from '../../components/ImageUpload';

type Props = {};

function BrandingTab({}: Props) {
  return (
    <div className="p-10">
      <ImageUpload />
      <p className="text-sm pt-3">
        Please upload a white version of your company logo (Transparent PNG
        file)
      </p>
    </div>
  );
}

export default BrandingTab;
